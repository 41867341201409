<template>
	<div
		class="h-[30px] w-full z-10 bg-neutral-50 fixed top-0 hidden md:flex justify-between items-center page-px text-[14px]"
	>
		<div v-if="props.isEditorial">
			<LanguageDropdown v-if="featureFlags.translations" />
		</div>

		<div :class="props.isEditorial ? 'absolute left-1/2 -translate-x-1/2' : ''">
			{{ props.customMsg }}
		</div>
		<div class="flex items-center gap-4">
			<NuxtLink
				v-if="about_page_published"
				to="/about-us"
				class="text-gray-500 cursor-pointer"
				>{{ $t("components.nav.about_us") }}</NuxtLink
			>
			<NuxtLink
				to="/contact-us"
				class="text-gray-500 cursor-pointer"
				>{{ $t("components.nav.contact") }}</NuxtLink
			>
			<LanguageDropdown v-if="!props.isEditorial && featureFlags.translations" />
		</div>
	</div>
</template>
<script lang="ts" setup>
import LanguageDropdown from "@/components/Nav/LanguageDropdown.vue";

const { platform, featureFlags } = storeToRefs(usePlatformStore());

const about_page_published = computed(() => {
	return platform.value?.platform_settings[0].pages.filter(p => p.page_name === "About Us")[0]?.publish_state;
});

interface TopBarProps {
	isEditorial: boolean;
	customMsg?: "string";
}
const props = defineProps<TopBarProps>();
</script>

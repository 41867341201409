<template>
	<v-menu>
		<template #activator="{ props }">
			<div
				v-bind="props"
				class="flex items-center gap-1 cursor-pointer"
			>
				<button>
					{{ $t(`general.langs.${selected}`) }}
				</button>
				<Icon name="md-expand_more" />
			</div>
		</template>
		<div class="w-full bg-white shadow rounded-sm">
			<div
				v-for="lang in languages"
				:key="lang.code"
				class="py-3 px-4 hover:bg-gray-200 rounded-sm"
				@click="switchTo(lang)"
			>
				<button>{{ $t(`general.langs.${lang.name}`) }}</button>
			</div>
		</div>
	</v-menu>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const supportedLanguages = [
	{ name: "english", code: "en" },
	{ name: "french", code: "fr" },
];

const { locale } = useI18n();
const selected = ref("english");
const languages = computed(() => {
	return supportedLanguages.filter(lg => lg.name !== selected.value);
});

function switchTo(lang: { name: string; code: string }) {
	if (!supportedLanguages.find(l => l.code === lang.code)) return;
	selected.value = lang.name;
	locale.value = lang.code;
	localStorage.setItem("lang", JSON.stringify(lang));
}

onMounted(() => {
	const cached = localStorage.getItem("lang");
	if (cached) switchTo(JSON.parse(cached));
});
</script>
